import React from 'react';
import {Redirect, Route, Switch} from 'react-router';
import NotFound from './pages/NotFound';
import Programs from "./pages/Programs";
import SignOut from "./pages/SignOut";
import SignIn from "./pages/SignIn";
import ProgramsProvider from "./components/Providers/ProgramsProvider";
import MyRegistrations from "./pages/MyRegistrations";
import Instructions from "./pages/Instructions";
import EditProfile from "./pages/EditProfile";

const Routes : React.FC = () => (
    <Switch>
        <ProgramsProvider>
            <Route path="/programs" exact component={Programs}/>
            <Route path="/edit-profile" exact component={EditProfile}/>
            <Route path="/my-registrations" exact component={MyRegistrations}/>
            <Route path="/instructions" exact component={Instructions}/>
            <Route path="/signout" exact component={SignOut}/>
            <Route exact path="/">
                <Redirect to="/programs" />
            </Route>
        </ProgramsProvider>
        <Route path="/signin" exact component={SignIn}/>
        <Route path="*" component={NotFound}/>
    </Switch>
);

export default Routes;
