import React from 'react';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import Spinner from "react-bootstrap/Spinner";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

type Props = {
    formIsModified: boolean;
    loading: boolean;
    submitForm: () => Promise<void>;
    showSave: boolean;
    submitType: string;
    isValid : boolean;
    saveText?: string;
    saveTextAction?: string;
    saveTextPast?: string;
    saveIcon?: IconProp;
    disableAutoSave?: boolean;
};

const FormControls = ({
    formIsModified,
    loading,
    submitForm,
    showSave,
    submitType,
    isValid,
    saveText,
    saveTextAction,
    saveTextPast,
    saveIcon,
    disableAutoSave
} : Props) => {
    React.useEffect(() => {
        if (!disableAutoSave) {
            const timer = setTimeout(() => {
                if (formIsModified && isValid) {
                    submitForm();
                }
            }, 15000);
            return () => clearTimeout(timer);
        }
    }, [submitForm, formIsModified, disableAutoSave, isValid]);

    const saveTextDefault = saveText ? saveText : 'Save';
    const saveTextActionDefault = saveTextAction ? saveTextAction : 'Saving';
    const saveTextPastDefault = saveTextPast ? saveTextPast : 'Saved';

    return (
        <>
            {showSave && (
                <Button
                    variant={formIsModified ? (!isValid ? 'danger' : 'secondary') : (!isValid ? 'outline-danger' : 'outline-primary')}
                    type="submit"
                    className="d-flex pt-2 flex-row align-content-center"
                    disabled={loading || !isValid}
                >
                {loading && submitType === 'save' ? (
                    <React.Fragment>
                        <Spinner animation="border" size="sm" className="mt-1"/>
                        &nbsp; {saveTextActionDefault}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {saveIcon ? (
                            <FontAwesomeIcon icon={saveIcon} size="sm" className="mt-1"/>
                        ) : (
                            <FontAwesomeIcon icon={faSave} size="sm" className="mt-1"/>
                        )}
                        &nbsp; {formIsModified ? saveTextDefault : saveTextPastDefault}
                    </React.Fragment>
                )}</Button>
            )}
        </>
    );
};

export default FormControls;