import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Formik, FormikValues} from "formik";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Alert, Button, Col, Form, Row, Spinner} from "react-bootstrap";
import * as yup from 'yup';
import StandardField from "../Forms/Fields/StandardField";
import LocalStorageTimer from "../LocalStorageTimer";
import {apiEndpoint, authenticatedApiFetch, jwtContext} from "../Providers/JWTProvider";
import {ProgramCourse, ProgramsProviderContext} from "../Providers/ProgramsProvider";
import ModalBase from "./ModalBase";
import {ErrorBoundary, FallbackProps} from 'react-error-boundary'

type Props = {
    course: ProgramCourse;
    programName: string;
    show: boolean;
    handleClose: (showCancelModal?: boolean, showConfirmModal?: boolean) => void;
    setConfirmationId: (confirmationId: string) => void;
    switchPayment?: boolean;
    courseHasCreditsApplied: boolean;
};

const ErrorFallback = ({error , resetErrorBoundary} : FallbackProps) => {
    return (
        <div role="alert">
            <p>There was a issue loading the credit card payments</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    )
};


var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

// @todo do we need to hydrate existing payment details into modal onload?

const PaymentModal = ({
    course,
    programName,
    show,
    handleClose,
    setConfirmationId,
    switchPayment,
    courseHasCreditsApplied,
} : Props) => {
    const stripe = useStripe();
    const elements = useElements();
    const apiFetch = authenticatedApiFetch();
    const user = React.useContext(jwtContext)
    const [, performRegistrationAction] = React.useContext(ProgramsProviderContext);
    const resetRegistrationStatus = React.useContext(ProgramsProviderContext)[5];
    const [, setFormIsModified] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [cancellationPending, setCancellationPending] = useState(false);

    const timeout = useMemo(() : string | null => {
        const localStorageItemString = localStorage.getItem('registration-course-' + course.courseSelectionUUID);
        const localStorageItem = localStorageItemString ? JSON.parse(localStorageItemString) : null;
        return localStorageItem && 'timeout' in localStorageItem && typeof localStorageItem.timeout === 'string'
            ? localStorageItem.timeout satisfies string : null;
    }, [course, show])

    useEffect(() => {
        setIsProcessing(false);
        setCancellationPending(false);
    }, [show]);

    const freeMode = course.financialAid === 'free' && course.cost === 0;
    const courseCostIsZero = course.cost === 0;
    const waitlistMode = course.status === 'waitlisted';
    const createPaymentIntent = async () => {
        const url = new URL(`/v1/payment/intent`, apiEndpoint);
        let init: RequestInit = {method: 'POST'};
        init.body = JSON.stringify({
            studentId: user?.studentId,
            courseSectionId: course.courseSelectionUUID,
        });

        const response = await apiFetch(url.toString(), init);

        if (!response.ok) {
            return null;
        }

        const responseJson = await response.json()
        return responseJson;
    };

    const confirmPayment = async (paymentType: string, transactionId: string, glenbrookPaymentId: number) => {
        const url = new URL(`/v1/payment/confirm-payment`, apiEndpoint);
        let init: RequestInit = {method: 'POST'};
        init.body = JSON.stringify({
            paymentType: paymentType,
            transactionId: transactionId,
            courseSectionId: course.courseSelectionUUID,
            studentId: user?.studentId,
            glenbrookPaymentId: glenbrookPaymentId
        });

        if (resetRegistrationStatus && switchPayment) {
            await resetRegistrationStatus(course);
        }

        const response = await apiFetch(url.toString(), init);
        return response;
    };

    const confirmMailIn = async (paymentType: string) => {
        const url = new URL(`/v1/payment/confirm-mailin`, apiEndpoint);
        let init: RequestInit = {method: 'POST'};
        init.body = JSON.stringify({
            paymentType: paymentType,
            courseSectionId: course.courseSelectionUUID,
            studentId: user?.studentId,
        });

        if (resetRegistrationStatus && switchPayment) {
            await resetRegistrationStatus(course);
        }

        const response = await apiFetch(url.toString(), init);
        return response;
    };

    const confirmFab = async () => {
        const url = new URL(`/v1/payment/confirm-fab`, apiEndpoint);
        let init: RequestInit = {method: 'POST'};
        init.body = JSON.stringify({
            courseSectionId: course.courseSelectionUUID,
            studentId: user?.studentId,
        });

        if (resetRegistrationStatus && switchPayment) {
            await resetRegistrationStatus(course);
        }

        const response = await apiFetch(url.toString(), init);
        return response;
    };

    const confirmFreeAfterCreditsApplied = async () => {
        const url = new URL(`/v1/payment/confirm-free-after-cc`, apiEndpoint);
        let init : RequestInit = {method: 'POST'};
        init.body = JSON.stringify({
            courseSectionId: course.courseSelectionUUID,
            studentId: user?.studentId,
        });

        const response = await apiFetch(url.toString(), init);
        return response;
    };

    const confirmWaitlist = async () => {
        const url = new URL(`/v1/payment/confirm-waitlist`, apiEndpoint);
        let init: RequestInit = {method: 'POST'};
        init.body = JSON.stringify({
            courseSectionId: course.courseSelectionUUID,
            studentId: user?.studentId,
        });

        const response = await apiFetch(url.toString(), init);
        return response;
    };

    const handleStripeSubmit = async (values: FormikValues) => {
        setErrorMessage('');

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const cardElement = elements.getElement(CardElement);

        if (!cardElement) {
            return;
        }

        setIsProcessing(true);
        const {error: paymentMethodError, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });
        if (paymentMethodError || !paymentMethod) {
            // return onUpdate();
            // handle error
            setErrorMessage(paymentMethodError && paymentMethodError.message ? paymentMethodError.message : 'Error creating payment.');
            setIsProcessing(false);
            return;
        }
        // create payment intent on server
        const initialPaymentIntent = await createPaymentIntent();
        if (!initialPaymentIntent) {
            setErrorMessage('Error creating payment. Please contact the help desk.');
            setIsProcessing(false);
            return;
        }
        // confirm with stripe
        const {error: cardError, paymentIntent} = await stripe.confirmCardPayment(
            initialPaymentIntent.clientSecret,
            {
                payment_method: paymentMethod.id,
            }
        );

        // handle error
        if (cardError || !paymentIntent) {
            setErrorMessage(cardError && cardError.message ? cardError.message : 'Error creating payment. Please contact the help desk.');
            setIsProcessing(false);
            return;
        }

        // update status of registration
        if (performRegistrationAction) {
            const confirmResponse = await confirmPayment(values.paymentMethod, initialPaymentIntent.intent, initialPaymentIntent.glenbrookPaymentId);

            if (confirmResponse.ok) {
                const responseJson = await confirmResponse.json();
                if (responseJson.confirmationId) {
                    setConfirmationId(responseJson.confirmationId);

                    if (courseHasCreditsApplied) {
                        await performRegistrationAction(course, programName);
                    }
                    localStorage.removeItem('registration-course-' + course.courseSelectionUUID)
                } else if (responseJson.message) {
                    setErrorMessage(responseJson.message);
                    setIsProcessing(false);
                    return;
                } else {
                    setErrorMessage('Error creating payment. Please contact the help desk.');
                    setIsProcessing(false);
                    return;
                }
            } else {
                setErrorMessage('Error processing payment. Please contact the help desk.');
                setIsProcessing(false);
                return;
            }
        }

        handleClose(false, true);
        setIsProcessing(false);
    };

    const handleMailInSubmit = async (values: FormikValues) => {
        setIsProcessing(true);

        if (performRegistrationAction) {
            const confirmResponse = await confirmMailIn(values.paymentMethod);

            if (confirmResponse.ok) {
                const responseJson = await confirmResponse.json()
                setConfirmationId(responseJson.confirmationId);

                if (courseHasCreditsApplied) {
                    await performRegistrationAction(course, programName);
                }
                localStorage.removeItem('registration-course-' + course.courseSelectionUUID)
            }
        }

        handleClose(false, true);
        setIsProcessing(false);
    };

    const handleFabSubmit = async () => {
        setIsProcessing(true);

        if (performRegistrationAction) {
            const confirmResponse = await confirmFab();

            if (confirmResponse.ok) {
                const responseJson = await confirmResponse.json()
                setConfirmationId(responseJson.confirmationId);

                if (courseHasCreditsApplied) {
                    await performRegistrationAction(course, programName);
                }
                localStorage.removeItem('registration-course-' + course.courseSelectionUUID)
            }
        }

        handleClose(false, true);
        setIsProcessing(false);
    };

    const handleFreeAfterCourseCredits = async () => {
        setIsProcessing(true);

        if (performRegistrationAction) {
            const confirmResponse = await confirmFreeAfterCreditsApplied();

            if (confirmResponse.ok) {
                const responseJson = await confirmResponse.json()
                setConfirmationId(responseJson.confirmationId);
                if (courseHasCreditsApplied) {
                    await performRegistrationAction(course, programName);
                }
                localStorage.removeItem('registration-course-' + course.courseSelectionUUID)
            }
        }

        handleClose(false, true);
        setIsProcessing(false);
    }

    const handleWaitlistSubmit = async () => {
        setIsProcessing(true);

        if (performRegistrationAction) {
            const confirmResponse = await confirmWaitlist();

            if (confirmResponse.ok) {
                const responseJson = await confirmResponse.json()
                setConfirmationId(responseJson.confirmationId);
                localStorage.removeItem('registration-course-' + course.courseSelectionUUID)
            }
        }

        handleClose(false, true);
        setIsProcessing(false);
    };

    const handleSetFormIsModified = useCallback(async (formIsModified: boolean) => {
        setFormIsModified(formIsModified);
    }, [setFormIsModified]);

    const cancelRegistration = async (showCancelModal: boolean, reason : string) => {
        if (performRegistrationAction) {
            // cancel registration
            await performRegistrationAction(course, programName, reason);
        }

        localStorage.removeItem('registration-course-' + course.courseSelectionUUID);
        setCancellationPending(false);
        handleClose(showCancelModal);
    }

    const handleLocalCancelOrClose = () => {
        if(!courseHasCreditsApplied) {
            if (cancellationPending) {
                cancelRegistration(false, 'Clicked cancel on payment');
            } else {
                if (!switchPayment || timeout) {
                    setCancellationPending(true);
                } else {
                    handleClose(false, false);
                }
            }
        } else {
            handleClose(false, false);
        }
    }

    const timeOutCancelRegistration = async () => {
        cancelRegistration(true, 'Payment Timeout');
    }

    const initialValues = {
        paymentMethod: waitlistMode ? 'waitlist' : (freeMode ? 'fab' : ''),
    }

    const schema = yup.object({
        paymentMethod: yup.string().required(),
    });

    const inputStyle = {
        iconColor: '#c4f0ff',
        fontWeight: '500',
        border: '1px solid black'
    }

    return (
        <ModalBase
            show={show}
            title="Payment Options"
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={cancellationPending ? () => { } : handleLocalCancelOrClose}
            backdrop="static"
            keyboard={false}
        >
            {timeout && (<Row className="chris-form-bg mx-0 py-1 px-2">
                <Col xs={12} style={{color: 'red', textAlign: 'right'}}>
                    You have <LocalStorageTimer timeout={timeout} callback={timeOutCancelRegistration} /> to Complete this step.
                </Col>
            </Row>)}
            <Row className="chris-form-bg mx-0 py-1 px-2">
                <Col xs={12}>
                    All fees must be paid in full by the due date for this activity. Any registrations with outstanding fees after the due date will be cancelled.
                </Col>
            </Row>
            <Row className="chris-form-bg mx-0 py-1 px-2">
                <Col xs={12}>
                    <div style={{textDecoration: 'underline', fontWeight: 800, fontSize: '120%'}}>Activity</div>
                    <div>{user?.nameFirst + ' ' + user?.nameLast + ' - ' + user?.studentId}</div>
                    <div>{course.name + ', Section ' + course.sectionNumber}</div>
                </Col>
            </Row>
            {!cancellationPending && <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={async (values) => {
                    if (values.paymentMethod === 'stripe') {
                        handleStripeSubmit(values);
                    } else if (values.paymentMethod === 'mail') {
                        handleMailInSubmit(values);
                    } else if (values.paymentMethod === 'fab') {
                        handleFabSubmit();
                    } else if (values.paymentMethod === 'freeAfterCourseCreditsApplied') {
                        handleFreeAfterCourseCredits();
                    } else if (values.paymentMethod === 'waitlist') {
                        handleWaitlistSubmit();
                    }
                }}
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
            >
                {(props) => {
                    const formValues = props.values;
                    const submitForm = props.submitForm;

                    return <Form.Group>
                        {waitlistMode && <Row className="chris-form-bg ml-2 pt-3">
                            <Col xs={12}>
                                <div style={{textDecoration: 'underline', fontWeight: 800, fontSize: '120%'}}>Payment Options</div>
                                <div className="mt-2 mb-2">Cost: {formatter.format(course.cost)}</div>
                                <p>The activity you requested is currently full. You will be added to the waitlist.</p>
                                <StandardField
                                    name="paymentMethod"
                                    type="hidden"
                                    label=""
                                    disabled={false}
                                    handleSetFormIsModified={(handleSetFormIsModified)}
                                />
                            </Col>
                        </Row>}
                        {courseCostIsZero && !waitlistMode && <Row className="chris-form-bg ml-2 pt-3">
                            <Col xs={12}>
                                <div style={{textDecoration: 'underline', fontWeight: 800, fontSize: '120%'}}>Payment Options</div>
                                    <div className="mt-2 mb-2">
                                        Cost: {formatter.format(course.cost)}&nbsp;
                                        {course.courseCredits && (
                                            <div style={{display: 'inline'}}>
                                                <span style={{textDecoration: 'line-through', color: 'red'}}>
                                                        {formatter.format(course.originalCost)}
                                                </span>
                                                <div style={{display: 'inline', color: 'green'}}>&nbsp;Course credits applied!</div>
                                            </div>
                                        )}
                                    </div>
                                    {freeMode ? <StandardField
                                            name="paymentMethod"
                                            type="radio"
                                            label=""
                                            disabled={false}
                                            handleSetFormIsModified={(handleSetFormIsModified)}
                                            options={[
                                                {
                                                    checked: true,
                                                    value: 'fab',
                                                    label: `Financial Assistance Benefit`
                                                },
                                            ]}
                                        />
                                        : <StandardField
                                            name="paymentMethod"
                                            type="radio"
                                            label=""
                                            disabled={false}
                                            handleSetFormIsModified={(handleSetFormIsModified)}
                                            options={[
                                                {
                                                    checked: true,
                                                    value: 'freeAfterCourseCreditsApplied',
                                                    label: `No Payment needed`
                                                },
                                            ]}
                                        />}
                            </Col>
                        </Row>}
                        {!courseCostIsZero && !waitlistMode && <>
                            <Row className="chris-form-bg ml-2 pt-3">
                                <Col xs={12}>
                                    <div style={{textDecoration: 'underline', fontWeight: 800, fontSize: '120%'}}>Payment Options</div>
                                    <div className="mt-2 mb-2">
                                        Cost: {formatter.format(course.cost)}&nbsp;
                                        {course.courseCredits && (
                                            <div style={{display: 'inline'}}>
                                                <span style={{textDecoration: 'line-through', color: 'red'}}>
                                                        {formatter.format(course.originalCost)}
                                                </span>
                                                <div style={{display: 'inline', color: 'green'}}>&nbsp;Course credits applied!</div>
                                            </div>
                                        )}
                                    </div>
                                    <StandardField
                                        name="paymentMethod"
                                        type="radio"
                                        label=""
                                        disabled={false}
                                        handleSetFormIsModified={(handleSetFormIsModified)}
                                        options={[
                                            {
                                                checked: false,
                                                value: 'mail',
                                                label: `Mail a Paper Check`
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>
                            {formValues.paymentMethod === 'mail' && <Row>
                                <Col xs={12}>
                                    <Alert variant="notice" className="m-0 pt-0 ml-4">
                                        <div>Submit all checks to:</div>
                                        <div>Glenbrook High School District 225</div>
                                        <div>ATTN: Enrollment Specialist</div>
                                        <div>3801 W Lake Ave.</div>
                                        <div>Glenview, IL  60026</div>
                                        <div>BE SURE TO INDICATE THE PARTICIPANT'S NAME <span style={{textDecoration: 'underline'}}>AND</span> ID NUMBER</div>
                                    </Alert>
                                </Col>
                            </Row>}
                            <Row className="chris-form-bg ml-2">
                                <Col xs={12}>
                                    <StandardField
                                        name="paymentMethod"
                                        type="radio"
                                        label=""
                                        disabled={false}
                                        handleSetFormIsModified={(handleSetFormIsModified)}
                                        options={[
                                            {
                                                checked: false,
                                                value: 'stripe',
                                                label: `Pay Online Using a Credit Card`
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>
                            {formValues.paymentMethod === 'stripe' && <Row className="px-4">
                                <Col xs={12} style={{
                                    border: ((stripe === null || elements === null) ? '2px solid #FF0000' : '2px solid #00f'),
                                    borderRadius: '8px',
                                    padding: '20px 4px'
                                }}>
                                    {
                                        (stripe === null || elements === null) ?  <p>Loading credit card payment form. If this message persists, try reloading the page or clearing your browser cache.</p> :
                                        <ErrorBoundary
                                            FallbackComponent={ErrorFallback}
                                            onReset={() => {
                                                formValues.paymentMethod = '';
                                            }}
                                        >
                                            <CardElement
                                                options={{
                                                    style: {
                                                        base: inputStyle,
                                                    }
                                                }}
                                            />
                                        </ErrorBoundary>
                                    }
                                </Col>
                                {errorMessage && <Col xs={12} className="mt-3" style={{color: 'red'}}>
                                    {errorMessage}
                                </Col>}
                            </Row>}
                        </>}
                        <Col xs={12}><hr /></Col>
                        {!cancellationPending && <Row className="mx-0 mt-2">
                            <Col xs={12} className="d-flex flex-row justify-content-space-between justify-content-lg-end">
                                <Button
                                    variant="secondary"
                                    type="button"
                                    className="d-flex pt-2 flex-row align-content-center mr-2"
                                    onClick={handleLocalCancelOrClose}
                                >Cancel</Button>
                                {!isProcessing && <Button
                                    variant={"primary"}
                                    type="button"
                                    className="d-flex pt-2 flex-row align-content-center mr-2"
                                    onClick={submitForm}
                                >{waitlistMode ? 'Join Waitlist' : 'Pay Now'}</Button>}
                                {isProcessing && <div className="d-flex pt-2 flex-row justify-content-center align-content-center mr-2" style={{minWidth: '94px'}}>
                                    <Spinner animation="border" size="sm" className="mt-1" />
                                </div>}
                            </Col>
                        </Row>}
                    </Form.Group>
                }}
            </Formik>}
            {cancellationPending && <>
                <Row className="mx-0 mt-2">
                    <Col xs={12} className="d-flex flex-row justify-content-space-between justify-content-lg-end">
                        <Alert variant="warning">Closing this window before payment is provided will cancel your current registration. Do you wish to continue?</Alert>
                    </Col>
                </Row>
                <Row className="mx-0 mt-2">
                    <Col xs={12} className="d-flex flex-row justify-content-space-between justify-content-lg-end">
                        <Button
                            variant="secondary"
                            type="button"
                            className="d-flex pt-2 flex-row align-content-center mr-2"
                            onClick={() => setCancellationPending(false)}
                        >No, Provide Payment</Button>
                        <Button
                            variant="primary"
                            type="button"
                            className="d-flex pt-2 flex-row align-content-center mr-2"
                            onClick={handleLocalCancelOrClose}
                        >Yes, Cancel Registration</Button>
                    </Col>
                </Row>
            </>}
        </ModalBase >);
}

export default PaymentModal;
